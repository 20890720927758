@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.container {
  width: 800px;
  display: flex;
  justify-content: center;
}

.container hr {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 40px;
  border-width: 2px;
  border-style: solid;
}

.container h3 {
  margin-bottom: 15px;
}

.container h5 {
  margin-bottom: 15px;
}

.descriptionLogin {
  animation-name: fadeIn;
  animation-duration: 5s;
  animation-iteration-count: initial;
}

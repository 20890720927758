#root {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tw-snackbar {
  height: 0px !important;
}

.tw-snackbar .tw-snackbar-background {
  display: none;
}
